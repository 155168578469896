import React from 'react'

import CardCreator from '../components/cardCreatorPage/CardCreator'

const stages = [
  {
    type: 1,
    name: 'Choose Design',
  },
  {
    type: 2,
    name: 'Customize Card',
  },
  {
    type: 3,
    name: 'Upload Photo',
  },
  {
    type: 4,
    name: 'Choose Size',
  },
]

class CardCreatorPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      maxSteps: 4,
    }
  }

  componentDidMount() {}

  goToStep = (stepNum) => {
    let { step, maxSteps } = this.state
    if (step < 1 || step > maxSteps) return
    this.setState({ step: stepNum })
  }

  goToNextStep = () => {
    let { step, maxSteps } = this.state
    if (step === maxSteps) return
    this.setState({ step: step + 1 })
  }

  goToPrevStep = () => {
    let { step } = this.state
    if (step === 1) return
    this.setState({ step: step - 1 })
  }

  render() {
    return (
      <div className="cardCreatorPage flex-center">
        <div className="container flex-column">
          <div className="cardCreatorPage__stages flex-center">
            {stages.map((item, index) => {
              return (
                <div
                  key={item.type}
                  className={'cardCreatorPage__stages__item' + (this.state.step === index + 1 ? ' active' : '')}>
                  <button onClick={() => this.goToStep(item.type)}>
                    {/*Step {index + 1}: <br/>*/}
                    {item.name}
                  </button>
                </div>
              )
            })}
          </div>

          <CardCreator
            goToNextStep={this.goToNextStep}
            goToPrevStep={this.goToPrevStep}
            goToStep={this.goToStep}
            step={this.state.step}
            maxSteps={this.state.maxSteps}
          />
        </div>
      </div>
    )
  }
}

export default CardCreatorPage
