import React from 'react'

import shipping from '../../assets/img/shipping.png'
import wallKit from '../../assets/img/wall_kit.png'

const CartAddons = (props) => {
  return (
    <>
      <div
        className={
          'cartItem cartAddon w-full d-flex' +
          (props.cartPage ? ' border-bottom-primary border-dashed' : ' orderAddons')
        }>
        <div className="cartItem__left d-flex">
          <div className="cartItem__img flex-center">
            <img src={shipping} alt="" />
          </div>

          <div className="cartItem__prodAboutCont">
            <div className="cartItem__text d-flex flex-column justify-center">
              <p className="cartItem__text__name semi-bold">Shipping Charge</p>
              {props.cartPage && (
                <p className="cartItem__text__size shippingLabelSm">Please add shipping address below</p>
              )}
            </div>
          </div>
        </div>

        <div className="cartItem__right flex-center">
          {props.cartPage ? (
            <p className="text-3 shippingLabel">Please add shipping address below</p>
          ) : (
            <p className="orderAddons__subPrice text-3">shipping $$</p>
          )}
        </div>
      </div>
      {!props.hideWallKit && (
        <div
          className={
            'cartItem cartAddon w-full d-flex' +
            (props.cartPage ? ' border-bottom-primary border-dashed' : ' orderAddons')
          }>
          <div className="cartItem__left d-flex">
            <div className="cartItem__img flex-center">
              <img src={wallKit} alt="" />
            </div>

            <div className="cartItem__prodAboutCont">
              <div className="cartItem__text d-flex flex-column justify-center">
                <p className="cartItem__text__name semi-bold">Wall Mounting Kit</p>
              </div>
            </div>
          </div>

          <div className="cartItem__right flex-center justify-end">
            {props.cartPage ? (
              <>
                <p className="cartAddon__kitPrice mr-3">£6.95</p>

                {!props.addWallKit ? (
                  <button
                    className="btn btn--primary mr-3 semi-bold"
                    onClick={() => props.updateState('addWallKit', true)}>
                    + ADD
                  </button>
                ) : (
                  <button
                    className="btn btn--primary mr-3 semi-bold"
                    onClick={() => props.updateState('addWallKit', false)}>
                    - REMOVE
                  </button>
                )}
              </>
            ) : (
              <p className="orderAddons__subPrice text-3">wall kit $$</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CartAddons
