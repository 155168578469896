import React from 'react'
import { Redirect } from 'react-router-dom'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'

import axios from '../helper/axios'

import arrow from '../assets/img/arrow_black.png'

import TableHeader from '../components/order/TableHeader'
import CartItem from '../components/cart/CartItem'
import CartAddons from '../components/cart/CartAddons'
import Loading from '../components/ui/Loading'

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      country: 'United States',
      region: '',
      cardData: {},
      redirectTo: null,
      cardQuantity: 1,
      addWallKit: false,
      loadingSecret: false,
      clientSecret: '',
    }
  }

  componentDidMount() {
    // console.log()

    let data = localStorage.getItem('card')

    let cardData = JSON.parse(data)
    if (cardData === null) {
      this.setState({ redirectTo: '/create' })
    } else {
      this.setState({ cardData })
    }
  }

  selectCountry = (val) => {
    this.setState({ country: val })
  }

  selectRegion = (val) => {
    this.setState({ region: val })
  }

  updateState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  checkout = async () => {
    this.setState({ loadingSecret: true })
    let client_secret = await this.getClientSecret()
    this.setState({ loadingSecret: false })

    if (client_secret) {
      // this.setState({
      //     clientSecret:client_secret,
      //     redirectTo:'/checkout'
      // })
      this.props.history.push('/checkout', { client_secret: client_secret })
    }
  }

  getClientSecret = async () => {
    try {
      // let sessionResult = await axios.post('/stripe-checkout/create-checkout-session/', JSON.stringify({
      //     priceId: this.state.cardData.cardSize.price_id,
      //     quantity: this.state.cardQuantity,
      //     wallKit: this.state.addWallKit
      // }))

      // let {sessionId} = sessionResult.data;
      //
      //
      // let SecretResult = await axios.get('/stripe-checkout/payment-intent-secret/', {
      //     params: {
      //         sessionId: sessionId
      //     }
      // });
      let paymentIntent = await axios.post('/stripe-checkout/create-payment-intent/', {
        items: [
          {
            priceId: this.state.cardData.cardSize.price_id,
          },
        ],
      })

      return paymentIntent.data.clientSecret
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { country, region } = this.state

    if (this.state.redirectTo)
      return (
        <Redirect
          to={{
            pathname: this.state.redirectTo,
            state: { client_secret: this.state.clientSecret },
          }}
        />
      )

    return (
      <>
        <div className="cart flex-center">
          <div className="container flex-column">
            <h1 className="heading-main">
              Your <span className="color-primary">Cart</span>
            </h1>

            <TableHeader name="Your Cards" text1="Qty" text2="Price" />

            <CartItem
              cartPage={true}
              data={this.state.cardData}
              cardQuantity={this.state.cardQuantity}
              updateState={this.updateState}
            />
            <CartAddons cartPage={true} addWallKit={this.state.addWallKit} updateState={this.updateState} />

            <div className="cartSubtotal w-full d-flex p-relative">
              <div className="cartSubtotal__subtotal w-full flex-center justify-end">
                <div className="doted-bg" />

                <p className="cartSubtotal__text text-3 semi-bold">SubTotal:</p>

                <p className="cartSubtotal__price text-5 semi-bold color-primary">
                  £
                  {(
                    (this.state.cardData?.cardSize?.price / 100) * this.state.cardQuantity +
                    (this.state.addWallKit ? 6.95 : 0)
                  ).toFixed(2)}
                </p>
              </div>
            </div>

            <div className="cartCta w-full d-flex">
              <div className="cartCta__email flex-center">
                <label htmlFor="emailField" className="color-primary">
                  Verify Your email to proceed :
                </label>

                <input id="emailField" type="email" placeholder="Your email adress here" />
              </div>

              <button className="btn" onClick={this.checkout}>
                {this.state.loadingSecret ? (
                  <Loading />
                ) : (
                  <>
                    <span className="text-3">FINALISE</span>
                    <img src={arrow} alt="->" />
                  </>
                )}
              </button>
            </div>

            <div className="cartAddress w-full d-flex flex-column">
              <div className="w-full pt-3 pb-3 d-flex border-bottom-primary">
                <div className="text-3 w-full">Shipping Address</div>
              </div>

              <form className="cartAddress__form d-flex">
                <div className="cartAddress__form__left">
                  <div className="inputGroup">
                    <label htmlFor="firstName">First Name *</label>

                    <input type="text" id="firstName" />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="lastName">Last Name *</label>

                    <input type="text" id="lastName" />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="streetAddress">Street Address *</label>

                    <input type="text" id="streetAddress" />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="phoneNum">Phone Number *</label>

                    <input type="text" id="phoneNum" />
                  </div>
                </div>

                <div className="cartAddress__form__right">
                  <div className="inputGroup">
                    <label htmlFor="country">Country *</label>

                    <CountryDropdown id="country" value={country} onChange={(val) => this.selectCountry(val)} />
                  </div>

                  <div className="inputGroup">
                    <label htmlFor="state">State/Province *</label>

                    <RegionDropdown
                      id="state"
                      country={country}
                      value={region}
                      onChange={(val) => this.selectRegion(val)}
                    />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="city">City *</label>

                    <input type="text" id="city" />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="zipCode">Zip/Postal Code *</label>

                    <input type="text" id="zipCode" />
                  </div>
                </div>

                <button type="submit" className="cartAddress__form__btn btn btn--primary--transparent">
                  UPDATE CART
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Cart
