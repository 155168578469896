import React from 'react'

import CartItem from '../cart/CartItem'
import CartAddons from '../cart/CartAddons'

const OrderItem = (props) => {
  return (
    <div className="orderItem w-full d-flex flex-column">
      <CartItem data={props.data} />
      <CartAddons hideWallKit={props.hideWallKit} />
    </div>
  )
}

export default OrderItem
