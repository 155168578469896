import React from 'react'
import eye from '../../assets/img/preview_eye.png'

const CartItem = (props) => {
  // const [cardQuantity, updateState]'cardQuantity', = useState(1)

  const decrementCount = () => {
    if (props.cardQuantity <= 1) props.updateState('cardQuantity', 1)
    else props.updateState('cardQuantity', props.cardQuantity - 1)
  }
  const incrementCount = () => {
    props.updateState('cardQuantity', props.cardQuantity + 1)
  }

  return (
    <div
      className={'cartItem w-full d-flex p-relative' + (props.cartPage ? ' border-bottom-primary border-dashed' : '')}>
      <div className="cartItem__left d-flex">
        <div className="cartItem__img flex-center">
          <img src={props.data.designImg} alt="" />
        </div>

        <div className="cartItem__prodAboutCont">
          <div className="cartItem__text d-flex flex-column justify-center">
            <p className="cartItem__text__name semi-bold">{props.data.designName}</p>
            <p className="cartItem__text__size">
              {/*300mm x 400mm*/}
              {props.data?.cardSize?.size}
            </p>
          </div>

          {props.cartPage && (
            <div className="cartItem__preview d-flex items-center">
              <img src={eye} alt="product" />
              <p>View Preview</p>
            </div>
          )}
        </div>
      </div>

      <div className="cartItem__right d-flex">
        <div className="cartItem__about flex-center">
          {props.cartPage ? (
            <div className="cartItem__about__counter">
              <span className="cartItem__about__counter__decrement" onClick={decrementCount}>
                -
              </span>

              <input
                type="number"
                min={1}
                max={9999}
                value={props.cardQuantity}
                onChange={(e) => {
                  if (e.target.value < e.target.min || e.target.value > e.target.max) return

                  props.updateState('cardQuantity', Number(e.target.value))
                }}
              />

              <span className="cartItem__about__counter__increment" onClick={incrementCount}>
                +
              </span>
            </div>
          ) : (
            <p className="cartItem__about__text color-secondary">Pending</p>
          )}
        </div>

        <p className="cartItem__detail flex-center semi-bold">
          {/*29.95*/}£{((props.data?.cardSize?.price / 100) * props.cardQuantity).toFixed(2)}
        </p>
      </div>
    </div>
  )
}

export default CartItem
