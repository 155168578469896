import React from 'react'
import { Redirect } from 'react-router-dom'
import arrow from '../../assets/img/arrow_green.png'

import CardPreview from './CardPreview'
import DesignChoiceStep from './DesignChoiceStep'
import CardCustomiseStep from './CardCustomiseStep'
import CardAddImageStep from './CardAddImageStep'
import CardFinalizeStep from './CardFinalizeStep'
import axios from '../../helper/axios'

class CardCreator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      designImg: '',
      designName: '',
      designId: '',
      cardTextColor: 'E1F600',
      club: '',
      country: '',
      country_id: '',
      club_id: '',
      userImage: '',
      cardSize: {
        // size: 'medium',
        // price_id: 'price_1JRAeDHFrXE3YD3cHdPnX2A6',
        // product_id: 'prod_K5LKaMLxGb38dF',
        // price: '1899'
      },
      cardContent: {
        overall_stat: '',
        position: '',
        player_name: '',
        stat_1_val: '99',
        stat_1_key: 'PAC',
        stat_2_val: '99',
        stat_2_key: 'SHO',
        stat_3_val: '99',
        stat_3_key: 'PAS',
        stat_4_val: '99',
        stat_4_key: 'DRI',
        stat_5_val: '99',
        stat_5_key: 'DEF',
        stat_6_val: '99',
        stat_6_key: 'PHY',
      },
      showContentErrorMsg: false,
      showUserImgErrorMsg: false,
      redirectTo: null,
      showToast: false,
      hideToastAlways: false,
    }
  }

  componentDidMount() {
    let cardData = JSON.parse(localStorage.getItem('card'))
    if (cardData === null) return

    this.setState({
      ...this.state,
      ...cardData,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step === this.props.step) return

    if (
      this.props.step === 2 &&
      !this.state.hideToastAlways &&
      !this.state.showToast &&
      this.state.showContentErrorMsg
    ) {
      this.setState({ showToast: true })
    } else if (this.props.step !== 2) {
      this.setState({ showToast: false })
    }
  }

  /**
   * @param {Object} stateToUpdate
   * @param {Boolean} isFirstLoad
   */
  updateCardState = (stateToUpdate, isFirstLoad = false) => {
    let currentState = { ...this.state }

    if (isFirstLoad) {
      for (let player_name in stateToUpdate) {
        if (currentState[player_name] !== '') stateToUpdate[player_name] = currentState[player_name]
      }
    }

    if (stateToUpdate['userImage'] || this.state.userImage !== '') {
      stateToUpdate['showUserImgErrorMsg'] = false
    }

    this.setState({
      currentState,
      ...stateToUpdate,
    })

    setTimeout(() => {
      this.saveCardDataLocally()
    }, 0)
  }

  updateCardContentState = (state, value, maxLength) => {
    if (value.length > maxLength) return

    let currentState = { ...this.state }
    let contentState = { ...currentState.cardContent }

    value = value.toString().toUpperCase()
    this.setState({
      ...currentState,
      cardContent: {
        ...contentState,
        [state]: value,
      },
    })

    const isContentValid = this.validateCardContent()
    if (isContentValid) {
      this.setState({
        showContentErrorMsg: false,
      })
    }

    setTimeout(() => {
      this.saveCardDataLocally()
    }, 0)
  }

  saveCardDataLocally = () => {
    console.log('saved locally')
    let data = {
      designImg: this.state.designImg,
      designName: this.state.designName,
      designId: this.state.designId,
      club: this.state.club,
      country: this.state.country,
      userImage: this.state.userImage,
      cardSize: this.state.cardSize,
      cardContent: this.state.cardContent,
    }
    localStorage.setItem('card', JSON.stringify(data))
  }

  validateCardContent = () => {
    let cardContent = this.state.cardContent

    let isValid = true

    for (const cardContentKey in cardContent) {
      if (!cardContent[cardContentKey].length) isValid = false
    }

    return isValid
  }

  validateAndConfirmHandler = async () => {
    const isContentValid = this.validateCardContent()
    const isimgValid = this.state.userImage !== ''

    if (!isContentValid) this.updateCardState({ showContentErrorMsg: true })
    else this.updateCardState({ showContentErrorMsg: false })

    if (!isimgValid) {
      this.updateCardState({ showUserImgErrorMsg: true })
    } else {
      this.updateCardState({ showUserImgErrorMsg: false })
    }

    if (!isContentValid) {
      this.props.goToStep(2)
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth',
      })
    } else if (!isimgValid) {
      this.props.goToStep(3)
    }

    if (!isContentValid || !isimgValid) return

    this.saveCardDataLocally()
    let endpoint = localStorage.getItem('uuid:jwt') ? '/cards/replace' : `/cards/create`
    let headers = { 'Content-Type': 'multipart/form-data' }
    headers['X-UUID'] = localStorage.getItem('uuid:jwt')
    let cardData = JSON.parse(localStorage.getItem('card'))
    let body = {
      ...cardData.cardContent,
      design_id: cardData.designId,
      size_id: cardData.cardSize.size_id,
      club_id: this.state.club_id,
      country_id: this.state.country_id,
    }

    await axios.post(endpoint, body, {
      headers: headers,
    })

    // this.setState({ redirectTo: '/cart' })
  }

  hideToast = () => {
    this.setState({ showToast: false })
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />

    return (
      <>
        <div className="cardCreator d-flex-center">
          {this.props.step > 1 && (
            <div className="cardCreator__arrow arrowLeft" onClick={this.props.goToPrevStep}>
              <img src={arrow} alt="" />
            </div>
          )}

          <div className="doted-bg" />

          <div className="container w-full" style={{ '--inputTextColor': '#' + this.state.cardTextColor }}>
            <CardPreview
              goToPrevStep={this.props.goToPrevStep}
              goToNextStep={this.props.goToNextStep}
              step={this.props.step}
              country={this.state.country}
              club={this.state.club}
              userImage={this.state.userImage}
              previewImg={this.state.designImg}
              cardContent={this.state.cardContent}
              updateCardContentState={this.updateCardContentState}
              showContentErrorMsg={this.state.showContentErrorMsg}
            />

            {/*Step 1*/}
            <DesignChoiceStep
              goToNextStep={this.props.goToNextStep}
              goToPrevStep={this.props.goToPrevStep}
              step={this.props.step}
              updateCard={this.updateCardState}
            />

            {/*Step 2*/}
            <CardCustomiseStep
              goToNextStep={this.props.goToNextStep}
              goToPrevStep={this.props.goToPrevStep}
              step={this.props.step}
              updateCard={this.updateCardState}
            />

            {/*Step 3*/}
            <CardAddImageStep
              goToNextStep={this.props.goToNextStep}
              goToPrevStep={this.props.goToPrevStep}
              step={this.props.step}
              updateCard={this.updateCardState}
              showError={this.state.showUserImgErrorMsg}
            />

            {/*Step 4*/}
            <CardFinalizeStep
              goToNextStep={this.props.goToNextStep}
              goToPrevStep={this.props.goToPrevStep}
              step={this.props.step}
              cardSize={this.state.cardSize}
              updateCard={this.updateCardState}
              confirm={this.validateAndConfirmHandler}
            />
          </div>

          {this.props.step < this.props.maxSteps && (
            <div className="cardCreator__arrow arrowRight" onClick={this.props.goToNextStep}>
              <img src={arrow} alt="" />
            </div>
          )}
        </div>

        <div className={'toast toast--error ' + (this.state.showToast ? 'slideIn' : 'slideOut')}>
          <div className="cross" onClick={this.hideToast} />
          <p>*Please fill all fields marked red.</p>

          <div className="toast__checkboxCont">
            <label>
              <input
                type="checkbox"
                value={this.state.hideToastAlways}
                onChange={() => {
                  this.setState({ hideToastAlways: !this.state.hideToastAlways })
                }}
              />

              <span>Don't show this again.</span>
            </label>
          </div>
        </div>
      </>
    )
  }
}

export default CardCreator
