import React from 'react'
import { Link } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

import arrowImg from '../assets/img/arrow.png'
import cardBronze from '../assets/img/cardBronze.png'
import cardSilver from '../assets/img/cardSilver.png'
import cardClub from '../assets/img/cardClub.png'
import cardEnglishLeague from '../assets/img/cardEnglishLeague.png'
import star from '../assets/img/starmain.png'
import badges from '../assets/img/ProductsMain.png'
import Trustpilot from '../assets/img/Trustpilot_logo.png'
import cardsgroup from '../assets/img/cardGroup.png'
import background from '../assets/img/BackgroundVectorSmartMain.png'
import arrowslide from '../assets/img/arrow2.png'

import step1 from '../assets/img/step1.png'
import step2 from '../assets/img/step2.png'
import step3 from '../assets/img/step3.png'
import step4 from '../assets/img/step4.png'
import step5 from '../assets/img/step5.png'

import { ReactComponent as Pro1 } from '../assets/img/svg/pro1.svg'
import { ReactComponent as Pro2 } from '../assets/img/svg/pro2.svg'
import { ReactComponent as Pro3 } from '../assets/img/svg/pro3.svg'
import { ReactComponent as Pro4 } from '../assets/img/svg/pro4.svg'
import { ReactComponent as Pro5 } from '../assets/img/svg/pro5.svg'
import { ReactComponent as Pro6 } from '../assets/img/svg/pro6.svg'
import { ReactComponent as Pro7 } from '../assets/img/svg/pro7.svg'
import { ReactComponent as Pro8 } from '../assets/img/svg/pro8.svg'
import { ReactComponent as Pro9 } from '../assets/img/svg/pro9.svg'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 3,
      maxSteps: 4,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <main>
        <div className="newsletterPromo">
          <p>
            <Link to="/" className="color-secondary">
              Sign up
            </Link>
            &nbsp;to our newsletter for 10%
          </p>
        </div>

        {/* Hero Section */}
        <section className="hero" style={{ backgroundImage: 'url(' + background + ')' }}>
          <div className="hero__container">
            <h2>
              Football season just got <br />
              10X Better with <span> Leaguecards</span>
            </h2>

            <div className="para">
              <p>
                Looking for the Perfect Gift ? Something that in unique and custom? <br />
                <span> You’re at the right place. Get your Customized Leaguecards today </span>
              </p>
            </div>

            <img className="cards mobile-cards" src={badges} alt="" />

            <div className="reverse">
              <div className="ratingHero">
                <img src={star} alt="start" />
                <p>Trusted by 3000+ Happy Customers</p>
              </div>
              <button className="btn herobtn">
                <a href="/create"> Order Now</a>
              </button>
            </div>

            <img className="cards desktop-cards" src={badges} alt="" />
          </div>
        </section>

        <section className="reviews">
          <div className="reviews__container">
            <div className="logo">
              <img src={Trustpilot} alt="" />
            </div>
            <div className="review">
              <div className="carosule">
                <Splide
                  options={{
                    type: 'loop',
                    perPage: 1,
                    perMove: 1,
                    pagination: false,
                    // isNavigation: true,
                    breakpoints: {
                      550: {
                        pagination: true,
                        arrows: false,
                      },
                    },
                  }}
                  renderControls={() => (
                    <div className="splide__arrows">
                      <button className="splide__arrow splide__arrow--prev">
                        <img src={arrowslide} alt="" />
                      </button>
                      <button className="splide__arrow splide__arrow--next">
                        <img src={arrowslide} alt="" style={{ transform: 'rotate(-180deg' }} />
                      </button>
                    </div>
                  )}>
                  <SplideSlide className="splide__slide">
                    <div className="reviewslide">
                      <p>
                        “At First I didn’t know what to expect and as such was caught by surprise when it came in the
                        mail.
                        <br /> My son Absolutely Loved it and it’s exactly as shown on the website. Delivery took 4
                        business days. Would Recommend.”
                      </p>
                      <span className="name"> -Sara.G </span>
                      <img src={star} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide className="splide__slide">
                    <div className="reviewslide">
                      <p>
                        “At First I didn’t know what to expect and as such was caught by surprise when it came in the
                        mail.
                        <br />
                        My son Absolutely Loved it and it’s exactly as shown on the website. Delivery took 4 business
                        days. Would Recommend.”
                      </p>
                      <span className="name"> -Abdul </span>
                      <img src={star} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide className="splide__slide">
                    <div className="reviewslide">
                      <p>
                        “At First I didn’t know what to expect and as such was caught by surprise when it came in the
                        mail.
                        <br />
                        My son Absolutely Loved it and it’s exactly as shown on the website. Delivery took 4 business
                        days. Would Recommend.”
                      </p>
                      <span className="name"> -Rizwan </span>
                      <img src={star} alt="" />
                    </div>
                  </SplideSlide>
                  <SplideSlide className="splide__slide">
                    <div className="reviewslide">
                      <p>
                        “At First I didn’t know what to expect and as such was caught by surprise when it came in the
                        mail.
                        <br />
                        My son Absolutely Loved it and it’s exactly as shown on the website. Delivery took 4 business
                        days. Would Recommend.”
                      </p>
                      <span className="name"> -Huraira </span>
                      <img src={star} alt="" />
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </section>

        {/* Card Design */}

        <section className="carddesign">
          <div className="carddesign__container">
            <div className="left">
              <h2>
                {' '}
                <span>Unique</span> Designs <br />
                to choose from{' '}
              </h2>
              <div className="para">
                <p className="hidden">
                  Our Design team is dedicated to creating unique <br />
                  designs to satisfy all our loyal customers and friends.
                </p>
                <br />
                <br />
                <p>
                  More than 100 designs to choose from and we will keep updating our inventory of designs. Check them
                  all out <br />
                  below.
                </p>
              </div>
              <button className="btn cardbtn">see all designs</button>
            </div>
            <div className="right">
              <img src={cardsgroup} alt="" />
            </div>
          </div>
        </section>

        <section className="sectionCardStyle d-flex-center" id="sectionCardStyle">
          <div className="sectionCardStyle__bg" />
          <div className="container d-flex-center flex-column">
            <h2>
              Card <span className="color-primary">Style</span>
            </h2>

            <div className="carosule">
              <Splide
                options={{
                  type: 'loop',
                  perPage: 3,
                  focus: 'center',
                  pagination: false,
                  isNavigation: true,
                  breakpoints: {
                    650: {
                      pagination: true,
                      arrows: false,
                    },
                  },
                }}
                renderControls={() => (
                  <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                      <img src={arrowImg} alt="" />
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                      <img src={arrowImg} alt="" style={{ transform: 'rotate(-180deg' }} />
                    </button>
                  </div>
                )}>
                <SplideSlide className="splide__slide">
                  <img src={cardBronze} alt="" />
                </SplideSlide>
                <SplideSlide className="splide__slide">
                  <img src={cardClub} alt="" />
                </SplideSlide>
                <SplideSlide className="splide__slide">
                  <img src={cardEnglishLeague} alt="" />
                </SplideSlide>
                <SplideSlide className="splide__slide">
                  <img src={cardSilver} alt="" />
                </SplideSlide>
              </Splide>
            </div>

            <Link to="/" className="btn btn--gradient text-bold">
              I WANT MY LEAGUE CARD NOW!
            </Link>

            <p className="sectionCardStyle__textSm mt-4">Show All</p>
          </div>
        </section>

        <section className="sectionWorking d-flex-center" id="sectionWorking">
          <div className="doted-bg" />

          <div className="container d-flex-center flex-column">
            <h2>
              How it <span className="color-primary">works</span>
            </h2>

            <div className="sectionWorking__stepsCont">
              <div className="sectionWorking__step">
                <div className="sectionWorking__step__text">
                  <p>Step 1</p>
                  <h4>
                    Choose <br />
                    your card
                  </h4>
                </div>
                <img src={step1} alt="" />
              </div>
              <div className="sectionWorking__step">
                <div className="sectionWorking__step__text">
                  <p>Step 2</p>
                  <h4>
                    Choose your <br />
                    team/country
                  </h4>
                </div>
                <img src={step2} alt="" />
              </div>
              <div className="sectionWorking__step">
                <div className="sectionWorking__step__text">
                  <p>Step 3</p>
                  <h4>
                    Add <br />
                    your stats
                  </h4>
                </div>
                <img src={step3} alt="" />
              </div>
              <div className="sectionWorking__step">
                <div className="sectionWorking__step__text">
                  <p>Step 4</p>
                  <h4>
                    Add <br />
                    your photo
                  </h4>
                </div>
                <img src={step4} alt="" />
              </div>
              <div className="sectionWorking__step">
                <div className="sectionWorking__step__text">
                  <p>Step 5</p>
                  <h4>
                    Choose <br />
                    the size
                  </h4>
                </div>
                <img src={step5} alt="" />
              </div>
              <div className="sectionWorking__step step-btn">
                <div className="sectionWorking__step__text">
                  <p>Step 6</p>
                  <h4>
                    Preview your <br />
                    card & click
                  </h4>
                </div>
                <button className="btn btn--gradient text-bold pt-2 pb-2">Order</button>
              </div>
            </div>
          </div>
        </section>

        <section className="sectionAbout d-flex-center">
          <div className="doted-bg doted-bg-gradient" />

          <div className="container d-flex-center flex-column">
            <h2>Personalised League Cards are the ultimate sports trophy</h2>
            <p>
              to show-off to your friends or give as a gift. Our cards offer the highest quality, fastest delivery with
              our advanced technology and state-of-the-art easy-to-use ordering configuration system.
            </p>
          </div>
        </section>

        <section className="sectionPros d-flex-center" id="sectionAbout">
          <div className="doted-bg" />

          <div className="container d-flex-center flex-column">
            <h2>
              Why <span className="color-primary">League Cards</span> are the Best
            </h2>

            <div className="sectionPros__prosCont">
              <div className="sectionPros__pro pro1">
                <Pro1 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-1" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">Personalised</span> with your photo, <br /> stats & favourite team
                </p>
              </div>
              <div className="sectionPros__pro pro2">
                <Pro2 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-2" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">7 card styles</span> to choose from
                </p>
              </div>
              <div className="sectionPros__pro pro3">
                <Pro3 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-3" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">3 card sizes</span> to <br />
                  choose from
                </p>
              </div>

              <div className="sectionPros__pro pro4">
                <Pro4 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-4" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">Strong & long-lasting </span>
                  <br />
                  foamex-board backing
                </p>
              </div>
              <div className="sectionPros__pro pro5">
                <Pro5 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-5" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">Fast shipping</span>
                  <br /> worldwide
                </p>
              </div>
              <div className="sectionPros__pro pro6">
                <Pro6 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-6" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">
                    Glossy <br />
                    laminate
                  </span>{' '}
                  finish
                </p>
              </div>
              <div className="sectionPros__pro pro7">
                <Pro7 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-7" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">
                    Glossy <br />
                    Easy
                  </span>{' '}
                  to order
                </p>
              </div>
              <div className="sectionPros__pro pro8">
                <Pro8 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-8" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">Unbeatable</span> price vs quality
                </p>
              </div>
              <div className="sectionPros__pro pro9">
                <Pro9 />
                <svg height="0" width="0">
                  <defs>
                    <linearGradient id="lgrad-9" gradientTransform="rotate(86.92)">
                      <stop offset="7.61%" stopColor="#ffffff" />
                      <stop offset="100%" stopColor="#ffffff" />
                    </linearGradient>
                  </defs>
                </svg>

                <p>
                  <span className="semi-bold">
                    Satisfaction or your <br /> money back Easy
                  </span>{' '}
                  guarantee
                </p>
              </div>
              <div className="sectionPros__pro sectionPros__btns d-flex-center">
                <p className="pro-last">
                  <span className="semi-bold">Preview</span>&nbsp;& click
                </p>
                <Link to="/" className="btn btn--gradient pt-2 pb-2 text-bold">
                  ORDER
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}

export default Home
