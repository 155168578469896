import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './assets/scss/main.scss'

import Home from './pages/Home'
import CardCreatorPage from './pages/CardCreatorPage'
import Cart from './pages/Cart'
import Order from './pages/Order'
import EmailVerify from './pages/EmailVerify'
import Checkout from './pages/Checkout'
import Layout from './components/ui/Layout'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/create"
            render={(props) => (
              <Layout hideFooter={true}>
                <CardCreatorPage {...props} />
              </Layout>
            )}
          />

          <Route
            path="/cart"
            render={(props) => (
              <Layout hideFooterNavLink={true}>
                <Cart {...props} />
              </Layout>
            )}
          />

          <Route
            path="/order"
            render={(props) => (
              <Layout hideFooterNavLink={true}>
                <Order {...props} />
              </Layout>
            )}
          />

          <Route
            path="/verify"
            render={(props) => (
              <Layout hideFooterNavLink={true}>
                <EmailVerify {...props} />
              </Layout>
            )}
          />

          <Route
            path="/checkout"
            render={(props) => (
              <Layout hideFooter={true} hideHeader={true}>
                <Checkout {...props} />
              </Layout>
            )}
          />

          <Route
            path="/"
            render={(props) => (
              <Layout hideNavItemOnHome={true}>
                <Home {...props} />
              </Layout>
            )}
          />

          {/* ADD IN PRODUCTION   */}
          {/*<Route*/}
          {/*    path="/landing"*/}
          {/*    render={()=><ReloadWindow/>}*/}
          {/*/>*/}

          {/*<Route exact path="/" render={() => (<Redirect to="/landing/" />)} />*/}
        </Switch>
      </Router>
    )
  }
}

export default App
