import React from 'react'

const Header = (props) => {
  return (
    <div className="tableHeader pt-4 pb-4 w-full d-flex p-relative border-bottom-primary">
      <div className="doted-bg" />

      <div className="tableHeader__title">
        <span>{props.name}</span>
      </div>
      <div className="tableHeader__info flex-center">{props.text1}</div>
      <div className="tableHeader__meta w-15 d-flex">{props.text2}</div>
    </div>
  )
}

export default Header
