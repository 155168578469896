import React, { useEffect, useState } from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import Loading from '../ui/Loading'

import { validateEmail } from '../../helper/formValidation'

const options = {
  showIcon: true,
  style: {
    base: {
      'color': '#424770',
      'letterSpacing': '0.025em',
      '::placeholder': {
        color: '#8792A2',
      },
      ':focus': {
        boxShadow:
          '0 0 0 1px rgba(230, 239, 255, 0.3), 0 1px 1px 0 rgba(0,0,0, 0.07), 0 0 0 4px rgba(230, 239, 255, 0.3)',
      },
    },
    invalid: {
      color: '#ff1c1c',
    },
  },
}

const CheckoutForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [paymentRequest, setPaymentRequest] = useState(null)

  const [showError, setShowError] = useState(false)
  const [cardError, setCardError] = useState('')

  const [email, setEmail] = useState('')
  const [isEmailInvalid, setEmailInvalid] = useState(false)
  const [designName, setCardName] = useState('')
  const [country, setCountry] = useState('')
  const [zipCode, setZipCode] = useState('')

  const [isProcessing, setProcessing] = useState(false)

  useEffect(() => {
    // console.log(stripe);
  }, [])

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  const emailValidation = () => {
    let isEmailValid = validateEmail(email)

    if (!isEmailValid) setEmailInvalid(true)
    else setEmailInvalid(false)

    return isEmailValid
  }

  const validateForm = () => {
    let isValid = true

    if (designName.length <= 0) isValid = false
    if (email.length <= 0) isValid = false
    if (country.length <= 0) isValid = false
    if (zipCode.length < 5 || zipCode.length > 10) isValid = false

    let isEmailValid = emailValidation()
    if (!isEmailValid) isValid = false

    return isValid
  }

  const disableInputs = () => {
    elements.getElement('cardNumber').update({
      classes: {
        base: 'StripeElement cardNumber borderBottomRadius-0 inputDisabled',
      },
    })
    elements.getElement('cardCvc').update({
      classes: {
        base: 'StripeElement cardCvcNumber borderTopRadius-0 inputDisabled',
      },
    })
    elements.getElement('cardExpiry').update({
      classes: {
        base: 'StripeElement cardExpiryNumber borderTopRadius-0 inputDisabled',
      },
    })
    setProcessing(true)
  }
  const enableInputs = () => {
    elements.getElement('cardNumber').update({
      classes: { base: 'StripeElement cardNumber borderBottomRadius-0' },
    })
    elements.getElement('cardCvc').update({
      classes: { base: 'StripeElement cardCvcNumber borderTopRadius-0' },
    })
    elements.getElement('cardExpiry').update({
      classes: { base: 'StripeElement cardExpiryNumber borderTopRadius-0' },
    })
    setProcessing(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) return

    disableInputs()

    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (paymentMethod.error) {
      // Show error to your customer (e.g., payment details incomplete)
      setCardError(paymentMethod.error.message)
      enableInputs()
    } else {
      setCardError('')
    }

    let isValid = validateForm()

    if (!isValid || paymentMethod.error) {
      setShowError(true)
      enableInputs()
      return
    }

    const result = await stripe.confirmCardPayment(
      props.clientSecret,
      {
        payment_method: paymentMethod.paymentMethod.id,
      },
      {
        confirmParams: {
          return_url: 'https://my-site.com/order/123/complete',
        },
      },
    )

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      enableInputs()
      console.log(result.error.message)
    } else {
      console.log('done successfully')
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form className="checkoutForm" onSubmit={handleSubmit}>
      {paymentRequest && (
        <>
          <PaymentRequestButtonElement
            options={{
              paymentRequest: paymentRequest,
              classes: {
                base: 'StripeElement paymentReqButton',
              },
            }}
          />
          <h5>Or pay with card</h5>
        </>
      )}

      <div className="formGroup">
        <label htmlFor="emailInput">
          <span>Email</span>
          {showError && email.length <= 0 ? (
            <span className="error--sm">Required</span>
          ) : isEmailInvalid ? (
            <div className="w-full d-flex justify-end">
              <span className="error--sm mt-1">Please enter correct Email.</span>
            </div>
          ) : null}
        </label>
        <div className="w-full">
          <input
            id="emailInput"
            type="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
              if (showError) emailValidation()
            }}
            disabled={isProcessing}
            className={
              (showError && (email.length <= 0 || isEmailInvalid) ? 'input--invalid' : '') +
              (isProcessing ? ' inputDisabled' : '')
            }
          />
        </div>
      </div>

      <div className="formGroup">
        <label htmlFor="cardNumInput">
          <span>Card information</span>
          {showError && cardError !== '' && <span className="error--sm">{cardError}</span>}
        </label>
        <div className="w-full" id="cardNumInput" aria-disabled={isProcessing}>
          <CardNumberElement
            options={{
              ...options,
              classes: {
                base: 'StripeElement cardNumber borderBottomRadius-0',
              },
            }}
            onChange={() => {
              setCardError('')
            }}
          />
        </div>
        <div className="w-full d-flex">
          <div className="w-50">
            <CardExpiryElement
              options={{
                ...options,
                classes: {
                  base: 'StripeElement cardExpiryNumber borderTopRadius-0',
                },
              }}
              onChange={() => {
                setCardError('')
              }}
            />
          </div>

          <div className="w-50">
            <CardCvcElement
              options={{
                ...options,
                classes: {
                  base: 'StripeElement cardCvcNumber borderTopRadius-0',
                },
              }}
              onChange={() => {
                setCardError('')
              }}
            />
          </div>
        </div>
      </div>

      <div className="formGroup">
        <label htmlFor="nameInput">
          <span>Name on card</span>
          {showError && designName.length <= 0 && <span className="error--sm">Required</span>}
        </label>
        <div className="w-full">
          <input
            id="nameInput"
            type="text"
            value={designName}
            disabled={isProcessing}
            onChange={(event) => setCardName(event.target.value)}
            className={
              (showError && designName.length <= 0 ? 'input--invalid' : '') + (isProcessing ? ' inputDisabled' : '')
            }
          />
        </div>
      </div>
      <div className="formGroup countryGroup">
        <label htmlFor="countryInput">
          <span>Country or region</span>
          {showError && (!country || zipCode.length < 1) ? (
            <span className="error--sm">Required</span>
          ) : showError && (zipCode.length < 5 || zipCode.length > 10) ? (
            <span className="error--sm">Zip Code not in correct Format.</span>
          ) : null}
        </label>
        <div className="w-full d-flex flex-column">
          <CountryDropdown
            id="countryInput"
            value={country}
            onChange={(val) => setCountry(val)}
            className={
              'borderBottomRadius-0 ' +
              (showError && !country ? 'input--invalid' : '') +
              (isProcessing ? ' inputDisabled' : '')
            }
          />
          <input
            type="text"
            placeholder="zip"
            value={zipCode}
            disabled={isProcessing}
            minLength={5}
            maxLength={10}
            onChange={(event) => setZipCode(event.target.value)}
            className={
              'borderTopRadius-0 ' +
              (showError && (zipCode.length < 5 || zipCode.length > 10) ? 'input--invalid' : '') +
              (isProcessing ? ' inputDisabled' : '')
            }
          />
        </div>
      </div>

      {/*<button type='submit' className='btn btn--primary' disabled={!stripe}>*/}
      <button type="submit" className="btn btn--primary">
        {isProcessing ? <Loading /> : `Pay £${(props.productData.cardSize.price / 100).toFixed(2)}`}
      </button>
    </form>
  )
}

export default CheckoutForm
