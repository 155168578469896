import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'

import logo from '../../assets/img/league_card_logo.png'

const Header = (props) => {
  const [hideNav, setHideNav] = useState(true)
  const [hideHeader, setHideHeader] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname.includes('/create')) setHideHeader(true)
  }, [history])

  return (
    <>
      <div
        className={clsx('flex-center headerMessage', !hideHeader && 'hideMessage')}
        onClick={() => setHideHeader(false)}>
        <p>We have hidden navbar to give you more space (Tap here to show)</p>
      </div>
      <div className={clsx('header', hideHeader && 'hideHeader')}>
        <div className="header__container container">
          <Link to="/">
            <img src={logo} alt="logo" className="header__logo" />
          </Link>

          <div className={clsx('hamburgerMenu', !hideNav && 'makeCross')} onClick={() => setHideNav(!hideNav)}>
            <div />
            <div />
            <div />
          </div>

          <ul className={clsx('header__nav', hideNav && 'hideNav')}>
            <li className="header__nav__link" onClick={() => setHideNav(true)}>
              <Link to="/">Home</Link>
            </li>
            {!props.hideNavItemOnHome && (
              <li className="header__nav__link" onClick={() => setHideNav(true)}>
                <Link to="/#sectionAbout">About Us</Link>
              </li>
            )}

            <li className="header__nav__link" onClick={() => setHideNav(true)}>
              <Link to="/#sectionWorking">How it works</Link>
            </li>
            {!props.hideNavItemOnHome && (
              <li className="header__nav__link" onClick={() => setHideNav(true)}>
                <Link to="/create">Order Now</Link>
              </li>
            )}

            <li className="header__nav__link" onClick={() => setHideNav(true)}>
              <Link to="/#sectionCardStyle">Our Cards</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Header
