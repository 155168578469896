import React from 'react'
import sizeCard from '../../assets/img/sizeCard.png'

const SizeCard = (props) => {
  return (
    <div
      className={
        'sizeCard pb-2 flex-center flex-column cardSm ' +
        (props.card.size.name + 'Card') +
        (props.cardSize.size === props.card.size.name ? ' active' : '')
      }
      onClick={() =>
        props.updateCard({
          cardSize: {
            size: props.card.size.name,
            price_id: props.card.price_id,
            product_id: props.card.product_id,
            size_id: props.card.size_id,
            price: props.card.price,
          },
        })
      }>
      <img src={sizeCard} alt="card" />
      <p className="text-1 mb-1">
        {/*210mm x 290mm*/}
        {props.card.size.name}
      </p>
      <p className="text-3 semi-bold">£{Number(props.card.price) / 100}</p>
    </div>
  )
}

export default SizeCard
