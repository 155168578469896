import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import * as JsSearch from 'js-search'

import axios from '../../helper/axios'

import SearchBox from '../input/SearchBox'
import TeamCard from './TeamCard'
import CountryCard from './CountryCard'
import Loading from '../ui/Loading'

const CardCustomiseStep = (props) => {
  const MY_STEP = 2

  const teamsContRef = useRef(null)
  const countriesContRef = useRef(null)

  const [teamValue, setTeamValue] = useState('')
  const [countryalue, setCountryValue] = useState('')

  // Have to use useStae with Ref so I can get the latest state, regular useState not providing latest state in date loading functions and scroll event callback
  const [teams, setTeams, teamsDataRef] = useState([])
  const [countries, setCountries, countriesDataRef] = useState([])

  const [, setAllTeamsLoaded, allTeamsLoadedRef] = useState(false)
  const [, setAllCountriesLoaded, allCountriesLoadedRef] = useState(false)

  const [teamsLoading, setTeamsLoading, teamsLoadingRef] = useState(false)
  const [countriesLoading, setCountriesLoading, countriesLoadingRef] = useState(false)

  const [selectedCountryID, setSelectedCountryID] = useState(null)
  const [selectedClubID, setSelectedClubID] = useState(null)

  const [countrySearch] = useState(new JsSearch.Search('id'))
  const [teamSearch] = useState(new JsSearch.Search('id'))

  const [countrySearchResult, setCountrySearchResult] = useState([])
  const [teamSearchResult, setTeamSearchResult] = useState([])

  useEffect(() => {
    countrySearch.addIndex('name')
    countrySearch.addIndex('country_code')
    countrySearch.addIndex('svg_code')
    getCountries()

    teamSearch.addIndex('name')
    teamSearch.addIndex('league')
    getTeams()

    teamsContRef.current.addEventListener('scroll', () => containerScroll('team'))
    countriesContRef.current.addEventListener('scroll', () => containerScroll('country'))
  }, [])

  const getCountries = () => {
    if (allCountriesLoadedRef.current || countriesLoadingRef.current) return
    setCountriesLoading(true)

    let data = countriesDataRef.current
    let limit = 999,
      pageNum = Math.ceil(data.length / limit) + 1

    axios
      .get('/countries', {
        params: {
          page: pageNum,
          limit: limit,
          perPage: limit,
        },
      })
      .then((result) => {
        console.log(result.data)
        if (!result.data.length) {
          setAllCountriesLoaded(true)
          return
        }
        if (countriesDataRef.current.length === 0) {
          let countrySVGCode = result.data[0].svg_code
          let countryId = result.data[0].id
          setSelectedCountryID(countrySVGCode)
          // let imgUrl = `https://api.leaguecards.com/v1/countries/${countrySVGCode}/png`
          let imgUrl = `https://cdn.leaguecards.com/countries/png/${countrySVGCode}.png`
          props.updateCard({ country: imgUrl, country_id: countryId }, true)
        }

        // setting here when load all countries at once
        setAllCountriesLoaded(true)
        setCountries([...data, ...result.data])

        // adding data for search
        countrySearch.addDocuments(result.data)
      })
      .then(() => {
        setCountriesLoading(false)
      })
  }

  const getTeams = () => {
    if (allTeamsLoadedRef.current || teamsLoadingRef.current) return
    setTeamsLoading(true)

    let data = teamsDataRef.current
    let limit = 999,
      pageNum = Math.ceil(data.length / limit) + 1

    axios
      .get('/clubs', {
        params: {
          page: pageNum,
          limit: limit,
          perPage: limit,
        },
      })
      .then((result) => {
        if (!result.data.length) {
          setAllTeamsLoaded(true)
          return
        }
        if (teamsDataRef.current.length === 0) {
          let clubId = result.data[0].id
          setSelectedClubID(clubId)
          let imgUrl = `https://cdn.leaguecards.com/clubs/png/${clubId}.png`
          // let imgUrl = `https://api.leaguecards.com/v1/clubs/${clubId}/png`
          props.updateCard({ club: imgUrl, club_id: clubId }, true)
        }

        // setting here when load all clubs at once
        setAllTeamsLoaded(true)
        setTeams([...data, ...result.data])

        // adding data for search
        teamSearch.addDocuments(result.data)
      })
      .then(() => {
        setTeamsLoading(false)
      })
  }

  const containerScroll = (contType) => {
    let contRef = teamsContRef,
      isLoading = teamsLoadingRef.current,
      allLoaded = allTeamsLoadedRef.current,
      getData = getTeams

    if (contType === 'country') {
      contRef = countriesContRef
      isLoading = countriesLoadingRef.current
      allLoaded = allCountriesLoadedRef.current
      getData = getCountries
    }

    let obj = contRef.current
    if (obj && obj.scrollTop >= obj.scrollHeight - obj.offsetHeight && !allLoaded && !isLoading) {
      getData()
    }
  }

  const clubClickHandler = (id) => {
    setSelectedClubID(id)
    // let imgUrl = `https://api.leaguecards.com/v1/clubs/${id}/png`
    let imgUrl = `https://cdn.leaguecards.com/clubs/png/${id}.png`
    props.updateCard({ club: imgUrl, club_id: id })
  }

  const countryClickHandler = ({ svg_code, id }) => {
    setSelectedCountryID(svg_code)
    // let imgUrl = `https://api.leaguecards.com/v1/countries/${id}/png`
    let imgUrl = `https://cdn.leaguecards.com/countries/png/${svg_code}.png`
    props.updateCard({ country: imgUrl, country_id: id })
  }

  const countryInputHandler = (e) => {
    let value = e.target.value
    setCountryValue(value)
    setCountrySearchResult(countrySearch.search(value))
  }

  const teamInputHandler = (e) => {
    let value = e.target.value
    setTeamValue(value)
    setTeamSearchResult(teamSearch.search(value))
  }

  return (
    <div
      className={
        'creatorHalfContainer cardCustomise  flex-center flex-column absolute-top-right step-' +
        MY_STEP +
        (props.step === MY_STEP ? ' fadeIn' : ' fadeOut')
      }>
      <h2>Customise your card</h2>

      <div className="creatorContentContainer pb-2 flex-center flex-column items-start">
        <div className="headingContainer">
          <h4>Select Team</h4>
          <SearchBox id="teamSearch" placeholder="Search team" value={teamValue} changeHandler={teamInputHandler} />
        </div>

        <div className="cardsContainer teamBoxes" ref={teamsContRef}>
          {teamSearchResult.length
            ? teamSearchResult.map((ele) => (
                <TeamCard key={ele.id} team={ele} clubClickHandler={clubClickHandler} selectedClub={selectedClubID} />
              ))
            : teams.map((ele) => (
                <TeamCard key={ele.id} team={ele} clubClickHandler={clubClickHandler} selectedClub={selectedClubID} />
              ))}
          {teamsLoading && <Loading />}
        </div>
      </div>

      <div className="creatorContentContainer countryContainer pb-3 flex-center flex-column items-start">
        <div className="headingContainer">
          <h4>Select Country</h4>
          <SearchBox
            id="countrySearch"
            placeholder="Search country"
            value={countryalue}
            changeHandler={countryInputHandler}
          />
        </div>

        <div className="cardsContainer countryBoxes" ref={countriesContRef}>
          {countrySearchResult.length
            ? countrySearchResult.map((ele) => (
                <CountryCard
                  key={ele.id}
                  country={ele}
                  countryClickHandler={countryClickHandler}
                  selectedCountry={selectedCountryID}
                />
              ))
            : countries.map((ele) => (
                <CountryCard
                  key={ele.id}
                  country={ele}
                  countryClickHandler={countryClickHandler}
                  selectedCountry={selectedCountryID}
                />
              ))}
          {countriesLoading && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default CardCustomiseStep
