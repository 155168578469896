import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'

import AboutCard from './AboutCard'
import axios from '../../helper/axios'
import Loading from '../ui/Loading'

const DesignChoiceStep = (props) => {
  const CURRENT_STEP = 1

  const cardsContRef = useRef(null)

  const [designs, setDesigns, designsDataRef] = useState([])
  const [allDesignsLoaded, setAllDesignsLoaded] = useState(false)
  const [designsLoading, setDesignsLoading] = useState(false)
  const [selectedCardID, setSelectedCardID] = useState(null)

  useEffect(() => {
    if (!designsDataRef.current.length)
      // for testing only
      getDesigns()
    // cardsContRef.current.addEventListener('scroll', containerScroll)
  }, [])

  const getDesigns = () => {
    if (allDesignsLoaded.current || designsLoading.current) return
    setDesignsLoading(true)

    let data = designsDataRef.current
    let limit = 50,
      pageNum = Math.ceil(data.length / limit) + 1

    axios
      .get('/designs', {
        params: {
          page: pageNum,
          limit: limit,
          perPage: limit,
        },
      })
      .then((result) => {
        if (!result.data.length) {
          setAllDesignsLoaded(true)
          return
        }
        if (designsDataRef.current.length === 0) {
          let designAsset = result.data[0].svg
          setSelectedCardID(designAsset)
          // let imgUrl = `https://api.leaguecards.com/v1/designs/${designAsset}/png`
          let imgUrl = `https://cdn.leaguecards.com/designs/300/${designAsset}.png`

          const cardState = {
            designImg: imgUrl,
            designAsset: result.data[0].svg,
            designName: result.data[0].name,
            designId: result.data[0].id,
            cardTextColor: result.data[0].textColor,
          }
          props.updateCard(cardState, true)
        }

        setDesigns([...data, ...result.data])
      })
      .then(() => {
        setDesignsLoading(false)
      })
  }

  const cardClickHandler = (cardData) => {
    console.log(cardData)
    setSelectedCardID(cardData.svg)
    let imgUrl = `https://cdn.leaguecards.com/designs/300/${cardData.svg}.png`
    // let imgUrl = `https://api.leaguecards.com/v1/designs/${cardData.id}/png`
    const cardState = {
      designImg: imgUrl,
      designName: cardData.name,
      designId: cardData.id,
      designAsset: cardData.svg,
      cardTextColor: cardData.textColor,
    }
    props.updateCard(cardState)
  }

  return (
    <div
      className={
        'creatorHalfContainer cardChoice flex-center flex-column absolute-top-right step-' +
        CURRENT_STEP +
        (props.step === CURRENT_STEP ? ' fadeIn' : ' fadeOut')
      }>
      <h2>Chose Your Card</h2>

      <div className="creatorContentContainer flex-center flex-column items-start">
        <div className="headingContainer">
          <h4>Most Popular</h4>
          <p>Card prices vary by size</p>
        </div>

        <div className="cardsContainer">
          {designs.map((ele, index) => {
            if (index > 2) return null
            return (
              <AboutCard key={ele.id} card={ele} selectedCardID={selectedCardID} cardClickHandler={cardClickHandler} />
            )
          })}

          {designsLoading && <Loading />}
        </div>

        <div className="headingContainer">
          <h4>All designs</h4>
        </div>

        <div className="cardsContainer cardsContainerLg" ref={cardsContRef}>
          {designs.map((ele, index) => {
            if (index <= 2) return null
            return (
              <AboutCard key={ele.id} card={ele} selectedCardID={selectedCardID} cardClickHandler={cardClickHandler} />
            )
          })}

          {designsLoading && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default DesignChoiceStep
