import React from 'react'

import magGlass from '../../assets/img/magnifying_glass.png'

const SearchBox = (props) => {
  return (
    <div className="searchBox">
      <label htmlFor={props.id}>
        <img src={magGlass} alt="search" />
      </label>

      <input
        id={props.id}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.changeHandler}
      />
    </div>
  )
}

export default SearchBox
