import axios from 'axios'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_API_URL}/v1`
      : `${process.env.REACT_APP_API_URL}/v1`,
  headers: {
    'content-type': 'application/json',
  },
})

export default instance
