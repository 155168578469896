import React from 'react'

const CardPreviewContent = (props) => {
  return (
    <div className={'cardPreview__cardContent' + (props.step === 2 ? ' active' : '')}>
      <div className="cardPreview__cardContent__top">
        <div className="cardPreview__cardContent__top__about">
          <input
            type="number"
            min={0}
            max={99}
            value={props.cardContent.overall_stat}
            placeholder="99"
            onChange={(e) => {
              props.updateCardContent('overall_stat', e.target.value, 2)
            }}
            className={
              'cardPreview__cardContent__top__about__num' +
              (props.showErrorMsg && !props.cardContent.overall_stat.length ? ' inputError' : '')
            }
          />

          <input
            type="text"
            value={props.cardContent.position}
            placeholder="CAM"
            onChange={(e) => {
              props.updateCardContent('position', e.target.value, 3)
            }}
            className={
              'cardPreview__cardContent__top__about__text' +
              (props.showErrorMsg && !props.cardContent.position.length ? ' inputError' : '')
            }
          />

          <div className="sepratorLine" />
          {/*<div className={"sepratorLine" + (props.step === 2 ? ' opacity-0' : '')}/>*/}

          {props.country !== '' ? (
            <img src={props.country} className="cardPreview__cardContent__top__about__countryFlag cardFlag" alt="" />
          ) : (
            <div className="cardFlag" />
          )}

          <div className="sepratorLine" />

          {props.club !== '' ? (
            <img src={props.club} className="cardPreview__cardContent__top__about__clubFlag cardFlag" alt="" />
          ) : (
            <div className="cardFlag" />
          )}
        </div>

        <div className="cardPreview__cardContent__top__img">
          {props.userImage !== '' ? (
            <img src={props.userImage} alt="" />
          ) : (
            <div className="cardPreview__cardContent__top__img__placeholder">
              <p>{props.step !== 3 ? 'Go to Step 3 to add a photo' : 'Add Image from Left'}</p>
            </div>
          )}
        </div>
      </div>

      <div className="cardPreview__cardContent__name">
        <input
          type="text"
          value={props.cardContent.player_name}
          placeholder="YOUR NAME"
          onChange={(e) => {
            props.updateCardContent('player_name', e.target.value, 15)
          }}
          className={props.showErrorMsg && !props.cardContent.player_name.length ? ' inputError' : ''}
        />
      </div>

      <div className="cardPreview__cardContent__stats">
        <div className="cardPreview__cardContent__stats__left flex-center flex-column">
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="33"
              value={props.cardContent.stat_1_val}
              onChange={(e) => {
                props.updateCardContent('stat_1_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_1_val.length ? ' inputError' : ''}
            />
            <input
              type="text"
              placeholder="PAC"
              value={props.cardContent.stat_1_key}
              onChange={(e) => {
                props.updateCardContent('stat_1_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_1_key.length ? ' inputError' : ''}
            />
          </div>
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="44"
              value={props.cardContent.stat_3_val}
              onChange={(e) => {
                props.updateCardContent('stat_3_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_3_val.length ? ' inputError' : ''}
            />

            <input
              type="text"
              placeholder="PAS"
              value={props.cardContent.stat_3_key}
              onChange={(e) => {
                props.updateCardContent('stat_3_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_3_key.length ? ' inputError' : ''}
            />
          </div>
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="55"
              value={props.cardContent.stat_5_val}
              onChange={(e) => {
                props.updateCardContent('stat_5_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_5_val.length ? ' inputError' : ''}
            />
            <input
              type="text"
              placeholder="DEF"
              value={props.cardContent.stat_5_key}
              onChange={(e) => {
                props.updateCardContent('stat_5_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_5_key.length ? ' inputError' : ''}
            />
          </div>
        </div>

        <div className="cardPreview__cardContent__stats__right flex-center flex-column">
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="66"
              value={props.cardContent.stat_2_val}
              onChange={(e) => {
                props.updateCardContent('stat_2_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_2_val.length ? ' inputError' : ''}
            />
            <input
              type="text"
              placeholder="SHO"
              value={props.cardContent.stat_2_key}
              onChange={(e) => {
                props.updateCardContent('stat_2_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_2_key.length ? ' inputError' : ''}
            />
          </div>
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="77"
              value={props.cardContent.stat_4_val}
              onChange={(e) => {
                props.updateCardContent('stat_4_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_4_val.length ? ' inputError' : ''}
            />
            <input
              type="text"
              placeholder="DRI"
              value={props.cardContent.stat_4_key}
              onChange={(e) => {
                props.updateCardContent('stat_4_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_4_key.length ? ' inputError' : ''}
            />
          </div>
          <div className="statsGroup">
            <input
              type="number"
              min={0}
              max={99}
              placeholder="88"
              value={props.cardContent.stat_6_val}
              onChange={(e) => {
                props.updateCardContent('stat_6_val', e.target.value, 2)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_6_val.length ? ' inputError' : ''}
            />
            <input
              type="text"
              placeholder="PHY"
              value={props.cardContent.stat_6_key}
              onChange={(e) => {
                props.updateCardContent('stat_6_key', e.target.value, 3)
              }}
              className={props.showErrorMsg && !props.cardContent.stat_6_key.length ? ' inputError' : ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardPreviewContent
