import React from 'react'

const AboutCard = (props) => {
  return (
    <div
      className={'aboutCard countryCard ' + (props.selectedCountry === props.country.svg_code ? ' active' : '')}
      onClick={() => props.countryClickHandler({ svg_code: props.country.svg_code, id: props.country.id })}>
      <img src={props.country.png_remote} alt="" />
      <div className="countryCard__text">
        <h3 className="countryCard__text__name">{props.country.name}</h3>
      </div>
    </div>
  )
}

export default AboutCard
