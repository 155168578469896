import React from 'react'

const TeamCard = (props) => {
  return (
    <div
      className={'teamCard d-flex w-full' + (props.selectedClub === props.team.id ? ' active' : '')}
      onClick={() => props.clubClickHandler(props.team.id)}>
      <img src={props.team.png_remote} alt="" />
      <div className="teamCard__text">
        <h3 className="teamCard__text__name">{props.team.name}</h3>
        <h2 className="teamCard__text__meta">{props.team.league}</h2>
      </div>
    </div>
  )
}

export default TeamCard
