import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'

function Layout(props) {
  return (
    <>
      {!props.hideHeader && <Header hideNavItemOnHome={props.hideNavItemOnHome} />}

      {props.children}

      {!props.hideFooter && <Footer hideNavLinks={props.hideFooterNavLink} />}
    </>
  )
}

export default Layout
