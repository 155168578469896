import React from 'react'

import card from '../assets/img/gold-card.png'

class EmailVerify extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verified: false,
    }
  }

  componentDidMount() {}

  setVerified = (value) => {
    this.setState({ verified: value })
  }

  render() {
    if (this.state.verified) {
      return (
        <>
          <div className="emailVerified flex-center">
            <div className="container flex-column">
              <div className="doted-bg" />

              <h1 className="heading-main">
                Your email has been <span className="color-primary">confirmed successfully</span>
              </h1>

              <p className="text-3 mt-4">You can sign in on the top right to view your orders</p>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="emailVerify flex-center">
          <div className="container flex-column">
            <div className="flex-center w-full flex-column">
              <h1 className="heading-main mb-1">Welcome</h1>
              <p className="text-3">your league card awaits you</p>
            </div>

            <div className="emailVerify__imgCont">
              <img src={card} alt="" />
            </div>

            <button className="btn btn--primary semi-bold" onClick={() => this.setVerified(true)}>
              VERIFY
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default EmailVerify
