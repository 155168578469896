import React from 'react'

import TableHeader from '../components/order/TableHeader'
import OrderItem from '../components/order/Ordertem'
import { Redirect } from 'react-router-dom'

class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      country: '',
      region: '',
      cardData: {},
      redirectTo: null,
    }
  }

  componentDidMount() {
    // console.log()

    let data = localStorage.getItem('card')

    let cardData = JSON.parse(data)
    if (cardData === null) {
      this.setState({ redirectTo: '/create' })
    } else {
      this.setState({ cardData })
    }
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />

    return (
      <>
        <div className="cart flex-center">
          <div className="container flex-column">
            <h1 className="heading-main">
              Your <span className="color-primary">Orders</span>
            </h1>

            <TableHeader name="Orders" text1="Status" text2="Order Date" />

            <OrderItem data={this.state.cardData} />
            <OrderItem data={this.state.cardData} hideWallKit={true} />
          </div>
        </div>
      </>
    )
  }
}

export default Order
