import React, { useEffect, useState } from 'react'
import axios from '../../helper/axios'

import 'cropperjs/dist/cropper.css'

import SizeCard from './SizeCard'
import Loading from '../ui/Loading'
import payment_method_apple from '../../assets/img/payment_method_apple.png'
import payment_method_google from '../../assets/img/payment_method_google.png'
import payment_method_paypal from '../../assets/img/payment_method_paypal.png'
import payment_method_skrill from '../../assets/img/payment_method_skrill.png'
import payment_method_stripe from '../../assets/img/payment_method_stripe.png'
import payment_method_visa from '../../assets/img/payment_method_visa.png'

function compare(a, b) {
  if (a.size_id < b.size_id) {
    return -1
  }
  if (a.size_id > b.size_id) {
    return 1
  }
  return 0
}

const CardFinalizeStep = (props) => {
  const MY_STEP = 4

  const [products, setProducts] = useState([])
  let [isLoading, setLoading] = useState(false)

  useEffect(() => {
    getProducts()
  }, [])

  const getProducts = () => {
    setLoading(true)

    axios
      .get('/products')
      .then((result) => {
        console.log(result.data)
        let products = result.data
        products.sort(compare)
        setProducts([...products])

        const cardSizeDetails = {
          size: products[0].size.name,
          size_id: products[0].size.id,
          price_id: products[0].price_id,
          product_id: products[0].product_id,
          price: products[0].price,
        }

        props.updateCard({ cardSize: cardSizeDetails }, true)
      })
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <div
      className={
        'creatorHalfContainer cardFinalize flex-center flex-column absolute-top-right step-' +
        MY_STEP +
        (props.step === MY_STEP ? ' fadeIn' : ' fadeOut')
      }>
      <h2>Finalize you card</h2>

      <div className="creatorContentContainer flex-center flex-column items-start">
        <div className="headingContainer flex-center w-full justify-center">
          <h4>Select size</h4>
        </div>

        <div className="cardFinalize__cardSizes w-full flex-center justify-between ">
          {!isLoading &&
            products.map((item) => {
              return <SizeCard key={item.id} card={item} updateCard={props.updateCard} cardSize={props.cardSize} />
            })}

          {isLoading && <Loading />}
        </div>

        <div className="headingContainer flex-center w-full justify-center pt-2 pb-3">
          <h4>Payment</h4>
        </div>

        <div className="cardFinalize__paymentBtns w-full">
          <div className="d-flex flex-column">
            <p className="text-1">Accepted Payment methods</p>

            <div className="d-flex cardFinalize__paymentBtns__methods mt-2">
              <img src={payment_method_apple} alt="apple" />
              <img src={payment_method_google} alt="google pay" />
              <img src={payment_method_paypal} alt="paypal" />
              <img src={payment_method_stripe} alt="stripe" />
              <img src={payment_method_skrill} alt="skrill" />
              <img src={payment_method_visa} alt="visa" />
            </div>
          </div>

          <button className="btn btn--primary" onClick={props.confirm}>
            Confirm & Pay
          </button>
        </div>
      </div>
    </div>
  )
}

export default CardFinalizeStep
