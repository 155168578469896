import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/img/league_card_logo.png'
import fbLlogo from '../../assets/img/facebook_logo.png'
import instaLogo from '../../assets/img/instagram_logo.png'

const Footer = (props) => {
  return (
    <div className="footer flex-center flex-column">
      <div className="footer__container container flex-center flex-column">
        <div className="footer__top">
          <img src={logo} alt="logo" className="footer__top__logo" />

          {!props.hideNavLinks && (
            <ul className="footer__top__nav d-flex">
              <li className="footer__top__nav__link">
                <Link to="/">Home</Link>
              </li>
              <li className="footer__top__nav__link">
                <Link to="/">Configurator</Link>
              </li>
              <li className="footer__top__nav__link">
                <Link to="/">Gallery</Link>
              </li>
              <li className="footer__top__nav__link">
                <Link to="/">About Us</Link>
              </li>
              <li className="footer__top__nav__link">
                <Link to="/">Privacy Policy</Link>
              </li>
            </ul>
          )}

          <div className="footer__top__follow d-flex">
            <p className="text-3">Follow us:</p>
            <Link to="https://facebook.com/">
              <img src={fbLlogo} className="ml-4 mr-4" alt="facebok" />
            </Link>
            <Link to="https://instagram.com/">
              <img src={instaLogo} alt="instagram" />
            </Link>
          </div>
        </div>
      </div>

      <div className="footer__bottom flex-center">Copyright League Cards {new Date().getFullYear()}</div>
    </div>
  )
}

export default Footer
