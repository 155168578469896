import React, { useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import axios from '../../helper/axios'

import 'cropperjs/dist/cropper.css'

import Loading from '../ui/Loading'

import infoIcon from '../../assets/img/info_icon.png'
import addImgIcon from '../../assets/img/add-img-icon.png'

const CardAddImageStep = (props) => {
  const cropperRef = useRef(null)
  const zoomRangeRef = useRef(null)
  const rotationRangeRef = useRef(null)

  const MY_STEP = 3

  let [cropperInstance, setCropperInstance] = useState(null)
  let [image, setImage] = useState(
    localStorage.getItem('uuid:jwt') ? localStorage.getItem(localStorage.getItem('uuid:jwt')) : null,
  )

  let [zoom, setZoom] = useState(0)
  let [zoomProgress, setZoomProgress] = useState(0)
  let [rotation, setRotation] = useState(0)
  let [rotationProgress, setRotationProgress] = useState('50')
  let [isLoadingImg, setLoadingImg] = useState(false)

  useEffect(() => {
    if (zoomRangeRef.current) {
      updatedSliderProgress({}, zoomRangeRef)
      updatedSliderProgress({}, rotationRangeRef)
    }
  }, [])

  const updatedSliderProgress = (e, ref) => {
    if (!cropperInstance) return

    const slider = ref.current
    const min = slider.min
    const max = slider.max
    const value = slider.value

    let progress = ((value - min) * 100) / (max - min)

    if (e?.target?.name === 'zoomRange') {
      setZoom(e.target.value)
      setZoomProgress(progress)
      cropperInstance.zoomTo(progress / 10)
    } else if (e?.target?.name === 'rotateRange') {
      setRotation(e.target.value)
      setRotationProgress(progress.toString())
      cropperInstance.rotateTo(value)
    }

    slider.style.background = `linear-gradient(to right, var(--progress-color) 0%, var(--progress-color) ${
      ((value - min) / (max - min)) * 100
    }%, #DEE2E6 ${((value - min) / (max - min)) * 100}%, #DEE2E6 100%)`
  }

  const setFile = async (file) => {
    setLoadingImg(true)

    let imageResp = await postImage(file)
    const blob = new Blob([imageResp.data])
    // const url = URL.createObjectURL(blob);

    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      let dataUrl = reader.result

      if (imageResp.headers['x-uuid']) {
        localStorage.setItem('uuid:jwt', imageResp.headers['x-uuid'])
        localStorage.setItem(imageResp.headers['x-uuid'], dataUrl.toString())
      }

      setLoadingImg(false)
      setImage(dataUrl)
      props.updateCard({ userImage: dataUrl })
    }
  }

  const postImage = async (file) => {
    let fd = new FormData()
    fd.append('file', file)
    let endpoint = '/cards/upload',
      headers = { 'Content-Type': 'multipart/form-data' }

    if (localStorage.getItem('uuid:jwt')) {
      endpoint = '/cards/replace'
      headers.uuid = localStorage.getItem('uuid:jwt')
    }

    return await axios.post(endpoint, fd, {
      headers: headers,
      responseType: 'arraybuffer',
    })

    // return await axios.post(`/cards/upload`, fd, {
    //     headers: headers,
    //     responseType: "arraybuffer",
    // });
  }

  const fileChangeHandler = (e) => {
    console.log(e.target.files)
    if (!e.target.files.length) return
    let file = e.target.files[0]

    //unset previous Image
    setImage(null)
    props.updateCard({ userImage: null })
    let uuid = localStorage.getItem('uuid:jwt')
    if (uuid) {
      localStorage.removeItem('uuid:jwt')
      localStorage.removeItem(uuid)
    }
    setFile(file)
  }

  const onDropHandler = (e) => {
    if (!e.dataTransfer.files.length) return

    setFile(e.dataTransfer.files[0])

    e.preventDefault()
  }

  const setImgInPreview = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    if (!cropper) return

    props.updateCard({ userImage: cropper.getCroppedCanvas().toDataURL() })
  }

  const rotateLeft = () => {
    if (!cropperInstance) return
    cropperInstance.scale(-1, 1)
  }

  const rotateRight = () => {
    if (!cropperInstance) return
    cropperInstance.scale(1, 1)
  }

  const finalizeImage = () => {
    props.goToNextStep()
  }

  return (
    <div
      className={
        'creatorHalfContainer cardAddImage flex-center flex-column absolute-top-right step-' +
        MY_STEP +
        (props.step === MY_STEP ? ' fadeIn' : ' fadeOut')
      }>
      <h2>Add Your Photo</h2>

      <div className="creatorContentContainer flex-center flex-column items-start">
        <div className="headingContainer">
          <h4>Add your photo</h4>

          <p className="flex-center">
            <span>more info</span>
            <img src={infoIcon} className="infoBoxImg" alt="" />
          </p>
        </div>

        {props.showError && <p className="errorMessage">*Please add image from here.</p>}

        <div className="cardAddImage__imgPreview">
          {image ? (
            <Cropper
              src={image}
              style={{ height: '100%', width: '100%' }}
              viewMode={2}
              dragMode={'move'}
              zoomOnWheel={false}
              zoomOnTouch={false}
              ref={cropperRef}
              autoCropArea={1}
              onInitialized={(data) => {
                setCropperInstance(data)
              }}
            />
          ) : (
            <CropperImageInputDisplay
              isLoadingImg={isLoadingImg}
              onDropHandler={onDropHandler}
              fileChangeHandler={fileChangeHandler}
            />
          )}
        </div>

        <div className="headingContainer mb-3">
          <h4>Zoom</h4>
        </div>
        <div className="cardAddImage__range mb-2">
          <span>0%</span>

          <div className="cardAddImage__range__inputCont">
            <input
              type="range"
              min="0"
              max="100"
              name="zoomRange"
              value={zoom}
              ref={zoomRangeRef}
              onChange={(e) => updatedSliderProgress(e, zoomRangeRef)}
            />

            <div
              className="range-value"
              style={{
                transform: `translateX(-${zoomProgress}%)`,
                left: zoomProgress + '%',
              }}>
              {Number(zoomProgress).toFixed(0)}%
            </div>
          </div>

          <span>100%</span>
        </div>

        <div className="headingContainer mb-3">
          <h4>Rotation</h4>
        </div>
        <div className="cardAddImage__range mb-2">
          <span>-180</span>
          <div className="cardAddImage__range__inputCont">
            <input
              type="range"
              min="-180"
              max="180"
              name="rotateRange"
              value={rotation}
              ref={rotationRangeRef}
              onChange={(e) => updatedSliderProgress(e, rotationRangeRef)}
            />

            <div
              className="range-value"
              style={{
                transform: `translateX(-${rotationProgress}%)`,
                left: rotationProgress + '%',
              }}>
              {/*{Number(rotationProgress).toFixed(0)}%*/}
              {rotation}
            </div>
          </div>
          <span>180</span>
        </div>

        <div className="cardAddImage__buttons w-full mt-5 flex-center justify-between">
          <div className="cardAddImage__buttons__left d-flex">
            <button className="btn btn--transparent" onClick={rotateLeft}>
              Rotate Left
            </button>
            <button className="btn btn--transparent ml-2 mr-2" onClick={rotateRight}>
              Rotate Right
            </button>
          </div>

          <button className="btn btn--transparent" onClick={setImgInPreview}>
            Set on Card
          </button>
        </div>

        <div className={'cardAddImage__buttons w-full mt-5 flex-center ' + (image ? 'justify-between' : 'justify-end')}>
          {image && (
            <label className="btn btn--transparent" htmlFor="changeImgInput">
              Change Image
              <input type="file" hidden={true} id="changeImgInput" onChange={fileChangeHandler} />
            </label>
          )}

          <button className="btn btn--primary" onClick={finalizeImage}>
            Finished
          </button>
        </div>
      </div>
    </div>
  )
}

export default CardAddImageStep

const CropperImageInputDisplay = (props) => {
  return (
    <div
      className="cardAddImage__imgPreview__initial flex-center"
      onDragOver={(e) => e.preventDefault()}
      onDrop={props.onDropHandler}>
      {props.isLoadingImg ? (
        <Loading />
      ) : (
        <>
          <label htmlFor="fileInput" className="flex-center flex-column">
            <img src={addImgIcon} alt="Add New" />

            <p>
              Drag & Drop or <span>Select image from files</span>
            </p>
          </label>
          <input type="file" hidden={true} id="fileInput" onChange={props.fileChangeHandler} />
        </>
      )}
    </div>
  )
}
