import React from 'react'
import Loading from '../ui/Loading'
import CardPreviewContent from './CardPreviewContent'

const CardPreview = (props) => {
  return (
    <>
      <div className="creatorHalfContainer cardPreview flex-center flex-column">
        <h2 className="previewHeadline">
          Your <span className="color-primary">Card</span>
        </h2>

        <div className="creatorContentContainer flex-center flex-column">
          {props.previewImg !== '' ? (
            <>
              <div className="cardPreview__cardContainer">
                {/*{*/}
                {/*    props.showContentErrorMsg && props.step === 2 &&*/}
                {/*    <p className='errorMessage'>*Please fill all fields marked red.</p>*/}
                {/*}*/}

                <img src={props.previewImg} alt="card" className="cardPreview__previewImg" />

                <CardPreviewContent
                  step={props.step}
                  country={props.country}
                  club={props.club}
                  userImage={props.userImage}
                  cardContent={props.cardContent}
                  updateCardContent={props.updateCardContentState}
                  showErrorMsg={props.showContentErrorMsg}
                />
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>

        <div className="cardPreview__borderRight" />
      </div>

      <div className="navigation-buttons w-full d-flex justify-between items-center">
        <button className="btn btn--transparent back-btn-sm" onClick={props.goToPrevStep} disabled={props.step === 1}>
          {'< Back'}
        </button>

        {props.step !== 4 && (
          <button className="btn btn--transparent next-btn-sm" onClick={props.goToNextStep}>
            {'Next > '}
          </button>
        )}
      </div>
    </>
  )
}

export default CardPreview
