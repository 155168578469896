import React from 'react'

const AboutCard = (props) => {
  return (
    <div
      className={'aboutCard flex-center' + (props.selectedCardID === props.card.svg ? ' active' : '')}
      onClick={() => {
        props.cardClickHandler(props.card)
      }}>
      {/* <img src={`https://api.leaguecards.com/v1/designs/${props.card.id}/png`} alt=""/> */}
      <img src={`https://cdn.leaguecards.com/designs/300/${props.card.svg}.png`} alt="" />
      <div className="aboutCard__text">
        <h3 className="aboutCard__text__name">{props.card.name}</h3>
        <h2 className="aboutCard__text__price">$18.95</h2>
      </div>
    </div>
  )
}

export default AboutCard
